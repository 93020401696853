@import "./fonts.scss";
@import "./colors.scss";

html {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

body {
  background: $dark;
  color: $white;
  margin: 0;

  $margin: 18px;
  padding: $margin;
  height: calc(100vh - 2 * $margin);
  width: calc(100vw - 2 * $margin);
  font-family: 'Mark Pro', sans-serif;
  font-weight: 400;

  #root, .App {
    height: 100%;
    width: 100%;
  }
}
