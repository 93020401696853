@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Mark Pro

@font-face {
  font-family: 'Mark Pro';
  src: url('../fonts/mark_pro.book.italic-webfont.woff2') format('woff2'),
       url('../fonts/mark_pro.book.italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Mark Pro';
  src: url('../fonts/mark_pro.book-webfont.woff2') format('woff2'),
       url('../fonts/mark_pro.book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Mark Pro';
    src: url('../fonts/mark_pro.medium.italic-webfont.woff2') format('woff2'),
         url('../fonts/mark_pro.medium.italic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Mark Pro';
    src: url('../fonts/mark_pro.medium-webfont.woff2') format('woff2'),
         url('../fonts/mark_pro.medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mark Pro';
    src: url('../fonts/mark_pro.bold-webfont.woff2') format('woff2'),
         url('../fonts/mark_pro.bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
  font-family: 'Mark Pro';
  src: url('../fonts/mark_pro.bold.italic-webfont.woff2') format('woff2'),
       url('../fonts/mark_pro.bold.italic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

